<template>
  <v-container>
    <v-row justify="center" align="start">
      <v-col cols="10" sm="5" md="4">
        <v-file-input
          ref="file"
          label="File input"
          outlined
          dense
          @change="handleFile"
          @click="color = defaultColor"
          @click:clear="color = defaultColor"
        ></v-file-input>
      </v-col>
      <v-col cols="2">
        <v-btn
          :loading="loading"
          :disabled="loading"
          :color="color"
          class="ma-0 pa-0 white--text"
          fab
          small
          @click="uploadFile"
        >
          <v-icon dark> mdi-cloud-upload </v-icon>
          <template v-slot:loader>
            <span class="custom-loader">
              <v-icon light>mdi-cached</v-icon>
            </span>
          </template>
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import api from "../plugins/axios";
export default {
  name: "Upload",
  data() {
    return {
      file: {},
      loading: false,
      defaultColor: "blue-grey",
      color: "blue-grey"
    };
  },

  methods: {
    handleFile(e) {
      this.file = e;
    },

    uploadFile() {
      this.loading = true
      console.log(this.file);
      let formData = new FormData();
      formData.append("file", this.file);

      api
        .post("/funds/history/upload", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            "Access-Control-Allow-Origin": "*",
          },
        })
        .then((resp) => {
          console.log(resp);
          console.log("SUCCESS!!");
          this.color = 'green'
        })
        .catch(() => {
          console.log("FAILURE!!");
          this.color = 'red'
        }).then(() => {
          console.log(this)
          this.loading = false
        });
    },
  },
};
</script>

<style>
.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}
@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>