<template>
  <v-app>
    <AppBar v-if="excludeNav" />

    <!-- Sizes your content based upon application components -->
    <v-main>
      <!-- Provides the application the proper gutter -->
      <v-container fluid>
        <!-- If using vue-router -->
        <router-view />
      </v-container>
    </v-main>

    <v-footer app>
      <!-- -->
    </v-footer>
  </v-app>
</template>

<script>
import AppBar from "./components/AppBar";
export default {
  name: "App",
  components: {
    AppBar,
  },
  data: () => ({
    //
    navExclusions: ["Chart", "ChartGroup"],
  }),
  computed: {
    excludeNav() {
      return !this.navExclusions.includes(this.$route.name);
    },
  },
};
</script>
