<template>
  <v-app-bar app color="blue" dense flat>
    <v-app-bar-nav-icon></v-app-bar-nav-icon>
    <router-link to="/" active-class="active" exact-active-class="exact-active" >
      <v-app-bar-title style="font-family: 'Brush Script MT'; font-size: 150%"
        >Niche AM</v-app-bar-title
      >
    </router-link>

    <v-spacer></v-spacer>

    <router-link to="/upload" class="le" active-class="active" exact-active-class="exact-active"> Upload </router-link>

    <v-btn icon>
      <v-icon>mdi-heart</v-icon>
    </v-btn>

    <v-btn icon>
      <v-icon>mdi-magnify</v-icon>
    </v-btn>
  </v-app-bar>
</template>

<script>
export default {
  name: "AppBar",

  data: () => ({
    //
  }),
};
</script>

<style scoped>
.active {
  color: rgba(0, 0, 0, 0.54);
  text-decoration: none;
  }

.exact-active {
  color: rgba(0, 0, 0, 0.54);
  text-decoration: none;
}

.le.exact-active {
  text-decoration: underline;
}

.le {
  color: rgba(0, 0, 0, 0.54);
  text-decoration: none;
}


</style>