import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Upload from '../views/Upload.vue'
import Chart from '../views/Chart.vue'
import ChartGroup from '../views/ChartGroup.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      title: 'charts',
    },
  },
  {
    path: '/upload',
    name: 'Upload',
    component: Upload
  },
  {
    path: '/chart/:fund',
    name: 'Chart',
    component: Chart,
    props: true
  },


  {
    path: '/chartgroup/:fundGroup',
    name: 'ChartGroup',
    component: ChartGroup,
    props: true
  }

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  document.title = `${process.env.VUE_APP_TITLE} - ${to.name}`
  next()
})

export default router
