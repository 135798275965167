<template>
  <v-container>
    <v-row>
      <v-col cols="12" md="6" lg="4">
        <v-select
          :items="funds"
          v-model="fund"
          label="fund"
          dense
          outlined
        ></v-select>
      </v-col>
    </v-row>
    <ChartHistory :fund="fund"/>
  </v-container>
</template>

<script>
import api from "../plugins/axios";
import ChartHistory from '../components/ChartHistory.vue'
export default {
  name: "Home",
  components: {
    ChartHistory
  },
  data() {
    return {
      fund: "",
      funds: [],
      selection: "YTD",
      offsets: ["1M", "3M", "6M", "YTD", "1Y", "3Y", "5Y", "ALL"],
      offsetDates: {},
    };
  },

  computed: {
  },

  methods: {
    async getData(endpoint, params) {
      try {
        let resp = await api.get(endpoint, {
          params: params,
        });
        return resp;
      } catch (error) {
        console.log(error);
      }
    },
  },
  async created() {
    console.log(api.defaults.baseURL);
    let resp = await this.getData("/funds/names");
    console.log(resp);
    this.funds = resp.data.data;
    this.fund = this.funds[0];
  },
};
</script>

<style>
</style>