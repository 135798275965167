import axios from 'axios'

const api = axios.create({
    baseURL: process.env.VUE_APP_ROOT_API,
    // baseURL: 'https://77451e04288b.ngrok.io',
    withCredentials: true,
    headers: {
        'Content-Type': "application/json",
    }
});

export default api