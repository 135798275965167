<template>

    <ChartHistory :fund="fund"></ChartHistory>
  
</template>

<script>
import ChartHistory from '../components/ChartHistory.vue'
export default {
  name: "Chart",
  props: ['fund'],
  components: {
    ChartHistory
  },

}
</script>

<style>

</style>