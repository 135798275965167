<template>
  <v-container>
    <v-row iv id="chart" justify="space-around">
      <v-col cols="12" md="10" lg="8">
        <v-btn-toggle
          v-model="selection"
          clolor="blue lighten-1"
          mandatory
          dense
        >
          <v-btn
            v-for="offset in offsets"
            :key="offset"
            :value="offset"
            @click="updateRange(offset)"
            x-small
          >
            {{ offset }}
          </v-btn>
        </v-btn-toggle>

        <v-chip
          label
          class="mx-1 text-caption font-weight-medium"
          :style="positivePerf ? { color: 'green' } : { color: 'red' }"
        >
          Perf as of {{formattedDate}}: {{ performance }}</v-chip
        >

        <div id="chart-timeline">
          <apexchart
            type="area"
            height="350"
            ref="chart"
            :options="chartOptions"
            :series="series"
          ></apexchart>
        </div>
        <v-data-table
        :headers="perfs_header_table"
        :items="perf_values"
        class="elevation-1"
        :hide-default-footer="true"
      ></v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>


<script>
import api from "../plugins/axios";
// import VueApexCharts from "vue-apexcharts";
export default {
  name: "ChartHistory",
  components: {
    // apexchart: VueApexCharts,
  },
  props: ["fund"],
  data() {
    return {
      endDate: null,
      perf: null,
      selection: "YTD",
      offsets: ["1M", "3M", "6M", "YTD", "1Y", "3Y", "5Y", "ALL"],
      offsetDates: {},
      perfs: {},
      series: [
        {
          name: "NAV",
          data: [],
        },
      ],
      chartOptions: {
        chart: {
          id: "area-datetime",
          type: "area",
          height: 350,
          zoom: {
            autoScaleYaxis: true,
          },
        },
        dataLabels: {
          enabled: false,
        },
        markers: {
          size: 0,
          style: "hollow",
        },
        stroke: {
          width: 1.5,
        },
        xaxis: {
          type: "datetime",
          min: this.minDate,
          tickAmount: 6,
        },
        tooltip: {
          x: {
            format: "dd MMM yyyy",
          },
        },
        fill: {
          type: "gradient",
          gradient: {
            shadeIntensity: 1,
            opacityFrom: 0.5,
            opacityTo: 0.0,
            stops: [0, 100],
          },
        },
      },
    };
  },

  computed: {
    minDate() {
      return this.series[0].data[0][0];
    },
    performance() {
      return this.formatPercentage(this.perf);
    },
    positivePerf() {
      return this.perf >= 0 ? 1 : 0;
    },
    formattedDate(){
      var d = new Date(this.endDate);
      var options = {
        year: "numeric",
        month: "2-digit",
        day: "numeric"
      };
      
      return d.toLocaleDateString("en", options) 
    },
    perf_values(){
      var perfs_header = JSON.parse(JSON.stringify(this.perfs));
      for (const key_perf of Object.keys(perfs_header)){
        perfs_header[key_perf] = this.formatPercentage(perfs_header[key_perf]);
      }
      var array_perf_objs = []
      array_perf_objs.push(perfs_header)
      return array_perf_objs;
    },
    perfs_header_table(){
      var headers_perfs = []
      for (const offset_header of this.offsets){
        var perf_obj = 
        {
          text: offset_header,
          value: offset_header
        };
        headers_perfs.push(perf_obj);
      }
      return headers_perfs;
    }
  },

  methods: {
    formatPercentage(value) {
      return Number(value).toLocaleString(undefined, {
        style: "percent",
        minimumFractionDigits: 2,
      });
    },
    async updateData(event) {
      let resp = await this.getData("funds/history", { fund: event });
      let newSeries = [...this.series];
      newSeries[0].data = resp.data.data;
      this.offsetDates = resp.data.offsets;
      this.perfs = resp.data.perfs;
      this.series = newSeries;
    },
    async getData(endpoint, params) {
      try {
        let resp = await api.get(endpoint, {
          params: params,
        });
        return resp;
      } catch (error) {
        console.log(error);
      }
    },
    updateRange(offset) {
      let startDate =
        this.offsetDates[offset] === undefined
          ? this.minDate
          : this.offsetDates[offset];
      let endDate = this.series[0].data[this.series[0].data.length - 1][0];
      console.log(this.perfs[offset]);
      this.endDate = endDate;
      this.perf = this.perfs[offset];
      this.$refs.chart.zoomX(startDate, endDate);
    },
  },
  watch: {
    fund: async function (newFund, oldFund) {
      console.log(oldFund);
      await this.updateData(newFund);
      this.updateRange("YTD");
    },
  },
  async mounted() {
    console.log(api.defaults.baseURL);
    if (this.fund) {
      await this.updateData(this.fund);
      this.updateRange("YTD");
    }
  },
};
</script>

<style>
